import React from "react";
import "./ViewUser.css";
import moment from 'moment';
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";

const ViewUser = ({ data }) => {
  console.log("Received Data :",data)
  const profileData = {
    profilePicture: `https://ifx-expo-server.xicsolutions.in/uploads/${data.user_img}`,
    UniqueId: data.unique_id,
    name: data.user_name,
    mobile: data.phone_no,
    email: data.email,
    handledBy: data.admin_name ? data.admin_name : "self",
    reg_date: data.created_at,
    signature: `https://ifx-expo-server.xicsolutions.in/uploads/${data.user_sign}`,
  };

  return (
    <Container>
      <div className="text-white mt-4 px-5">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10 col-sm-12 profile-container m-5">
            <div className="d-flex justify-content-center mt-5">
              <img
                src={profileData.profilePicture}
                alt="Profile"
                className="rounded-circle border border-light border-bold shadow"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="profile-info p-5 text-start w-100 ">
              <Row className="mb-3">
                <Col>
                  <strong>Name</strong>
                </Col>
                <Col>{profileData.name}</Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <strong>Unique Id</strong>
                </Col>
                <Col>{profileData.UniqueId}</Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <strong>Mobile</strong>
                </Col>
                <Col>{profileData.mobile}</Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <strong>Email</strong>
                </Col>
                <Col className="ml-5">{profileData.email}</Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <strong>Registered By</strong>
                </Col>
                <Col>{profileData.handledBy}</Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <strong>Registered Date</strong>{" "}
                </Col>
                <Col>
                  {moment(profileData.reg_date).format("DD-MM-YYYY HH:mm:ss")}
                </Col>
              </Row>
            </div>

            <Col className="text-center  container mb-5">
              <div className="bg-light text-dark rounded  ">
                <h3 className="mb-3  mt-1">Signature</h3>

                <img
                  src={profileData.signature}
                  alt="Signature"
                  className="rounded mb-3 "
                />
              </div>
            </Col>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViewUser;
