import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  DialogTitle,
  Select,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import UpdateRegistration from "./UpdateRegistration/UpdateRegistration";
import { FaEdit, FaSearch } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useNavigate, Link } from "react-router-dom";
import "./ScoreIndex.css";
import UpdateScore from "./UpdateScore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const ScoreIndex = () => {
  const admin_id = sessionStorage.getItem('admin_id');
  const navigate = useNavigate();
  const [scoreData, setScoreData] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [openDlt, setOpenDlt] = useState(false);
  const [dltData, setDltData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [openScore, setOpenScore] = useState(false);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    axios
      .get(`https://ifx-expo-server.xicsolutions.in/score/getUserScoreData`)
      .then((res) => {
        setScoreData(res.data);
        setFilteredDatas(res.data);
      })
      .catch((err) => {
        console.log("Registration Data is not fetched.", err);
      });
  }, [openUpdate, openDlt]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = scoreData.filter((item) => {
      return (
        (item.user_name && item.user_name.toLowerCase().includes(lowercasedQuery)) ||
        (item.email && item.email.toLowerCase().includes(lowercasedQuery)) ||
        (item.phone_no && item.phone_no.toLowerCase().includes(lowercasedQuery)) ||
        (item.country && item.country.toLowerCase().includes(lowercasedQuery)) ||
        (item.unique_id && item.unique_id.toLowerCase().includes(lowercasedQuery)) 
      );
    });
    setFilteredDatas(filtered);
  }, [searchQuery, scoreData]);

  const handleUpdate = (id) => {
    const selectData = scoreData.find((score) => score.score_id === id);
    if (selectData) {
      setOpenUpdate(true);
      setUpdateData(selectData);
    }
  };

  const handleUpdateSuccessMsg = () =>{
    setOpenUpdate(false);
    toast.success("Score Data Updated Successfully.",{autoClose:3000})
  }

  const handleDlt = (id) => {
    if (id) {
      setDltData(id);
      setOpenDlt(true);
    }
  };

  const handleConfirmDlt = () => {
    if (dltData) {
      axios
        .delete(
          `https://ifx-expo-server.xicsolutions.in/score/deleteUserScore/${dltData}`
        )
        .then((res) => {
          setOpenDlt(false);
        })
        .catch((err) => {
          console.log("Error Failed to delete data.", err);
        });
    }
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(scoreData.map((score) => score.score_id));
    }
    setSelectAll(!selectAll);
  };

  const handleExport = () => {
    if (selectedRows.length === 0) {
      toast.warning("Please select data .", { autoClose: 3000 });
    } else {
      const exportData = scoreData
        .filter((score) => selectedRows.includes(score.score_id))
        .map((score, index) => ({
          "S.No": index + 1,
          "Unique Id": score.unique_id,
          Name: score.user_name,
          "Mobile No": score.phone_no,
          "Mail Id": score.email,
          Score: score.score,
          admin_name: score.admin_name,
        }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Score Data");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "score_date.xlsx");
    }
  };

  const handleChangeDataPerPage = (e) => {
    const newDataPerPage =
      e.target.value === scoreData.length
        ? scoreData.length
        : parseInt(e.target.value, 10);
    setDataPerPage(newDataPerPage);
    setCurrentPage(1);
  };

  const firstIndexOfData = (currentPage - 1) * dataPerPage;
  const lastIndexOfData = currentPage * dataPerPage;
  const currentData = filteredDatas.slice(firstIndexOfData, lastIndexOfData);

  const handleActions = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleClose = () => {
    setOpenUpdate(false);
  };

  return (
    <div className="registration-index-section">
      <Container>
        <ToastContainer position="top-right" />
        <Row>
          <Col xs={12}>
            <h1
              className="text-center"
              style={{
                color: "#6000d9",
                fontWeight: "bold",
                fontFamily: "Gellix",
              }}
            >
              SCORE INDEX
            </h1>
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col
            xs={12}
            md={4}
            lg={4}
            className="d-flex justify-content-lg-start justify-content-md-start justify-content-center mt-md-0 mt-3"
          >
            <Button
              onClick={() => navigate(`/regForm/${admin_id}`)}
              className="navigate-btns"
            >
              Add
            </Button>

            <Button
              onClick={() => navigate("/adminIndex")}
              className="navigate-btns ms-3"
            >
              Home
            </Button>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className="d-flex justify-content-lg-start justify-content-md-end justify-content-center mt-md-0 mt-3"
          >
            <div className="search-container">
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <FaSearch className="searchicon" />
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={2}
            className="d-flex justify-content-lg-start justify-content-md-end justify-content-center mt-md-0 mt-3"
          >
            <Button onClick={handleExport} className="navigate-btns">
              Export
            </Button>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={2}
            className="d-flex justify-content-lg-end justify-content-md-center justify-content-center mt-md-5 mt-lg-0 mt-3"
          >
            <Select
              className="pagination-btn"
              value={dataPerPage}
              onChange={handleChangeDataPerPage}
            >
              <MenuItem value={20}>20 Per Page</MenuItem>
              <MenuItem value={30}>30 Per Page</MenuItem>
              <MenuItem value={50}>50 Per Page</MenuItem>
              <MenuItem value={scoreData.length}>All Per Page</MenuItem>
            </Select>
          </Col>
        </Row>
        <TableContainer className="registration-table-container table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  S.NO
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  PHOTO
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  UNIQUE ID
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  NAME
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  MOBILE NO
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  MAIL ID
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  SCORE
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  ENTERED BY
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  ACTIONS
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    inputProps={{ "aria-label": "select all" }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((score, index) => (
                <>
                  <TableRow key={score.score_id} className="table-row">
                    <TableCell className="table-cell-value">
                      {index + 1}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      <img
                        src={`https://ifx-expo-server.xicsolutions.in/uploads/${score.user_img}`}
                        alt={score.user_name}
                        style={{
                          width: "50px",
                          height: "53px",
                          borderRadius: "5px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {score.unique_id}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {score.user_name}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {score.phone_no}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {score.email}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {score.score}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {score.admin_name}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => handleActions(e, score.score_id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={
                          Boolean(anchorEl) && selectedId === score.score_id
                        }
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem onClick={() => handleUpdate(score.score_id)}>
                          <FaEdit />
                          &nbsp;&nbsp; Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDlt(score.score_id)}>
                          <BsTrash />
                          &nbsp;&nbsp; Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(score.score_id)}
                        onChange={() => handleSelectRow(score.score_id)}
                        inputProps={{
                          "aria-label": `select ${score.score_id}`,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Row className="mt-3">
          <Col xs={12} className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(filteredDatas.length / dataPerPage)}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
              />
            </Stack>
          </Col>
        </Row>
      </Container>

      {openUpdate && (
        <UpdateScore
          data={updateData}
          open={openUpdate}
          close={() => handleClose()}
          successMsg = {handleUpdateSuccessMsg}
        />
      )}

      <Dialog open={openDlt} onClose={() => setOpenDlt(false)}>
        <DialogContent>
          <p>Are you sure do you want to delete this user score ?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmDlt}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Delete
          </Button>
          <Button
            onClick={() => setOpenDlt(false)}
            style={{ backgroundColor: "green", color: "white" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScoreIndex;
